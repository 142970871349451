import { useContext, useState } from 'react'
import { Page, Card, SkeletonPage, InlineStack, Box } from '@shopify/polaris'
import '@shopify/polaris/build/esm/styles.css'
// import { useNavigate } from 'react-router-dom'
import { backendRequest } from '../../../shared/utils/axiosInstance'
import { DataUpdateContext } from '../../../shared/context/context'
import { useTranslation } from 'react-i18next'
import { PlanCard } from '../../../shared/components/PlanCard'

export const PlanSelectionView = () => {
  // const navigate = useNavigate()
  const { companyId, appBridge, subscriptionPlan } = useContext(DataUpdateContext)
  // const [loadingFreePlan, setLoadingFreePlan] = useState(false)
  const [loadingPremiumPlan, setLoadingPremiumPlan] = useState(false)
  const { t } = useTranslation()

  // const handleFreePlanSelection = async () => {
  //   setLoadingFreePlan(true)
  //   try {
  //     const { data: { return_url: returnUrl } } = await backendRequest({
  //       method: 'POST',
  //       url: '/shopify/subscriptions',
  //       appBridge,
  //       data: {
  //         company_id: companyId,
  //         plan: 'free'
  //       }
  //     })
  //     navigate(`/shopify/subscription/return?${returnUrl.split('?')[1]}`)
  //   } catch (error) {
  //     console.error('Failed to create free subscription.', error)
  //   } finally {
  //     setLoadingFreePlan(false)
  //   }
  // }

  const handlePremiumPlanSelection = async () => {
    setLoadingPremiumPlan(true)
    try {
      const { data: { confirmation_url: confirmationUrl } } = await backendRequest({
        method: 'POST',
        url: '/shopify/subscriptions',
        appBridge,
        data: {
          company_id: companyId,
          plan: 'premium'
        }
      })
      if (confirmationUrl) {
        window.open(confirmationUrl, '_top')
      }
    } catch (error) {
      console.error('Failed to create premium subscription.', error)
    } finally {
      setLoadingPremiumPlan(false)
    }
  }

  // const freePlanItems = [
  //   t('plan.replen.free.items.variants'),
  //   t('plan.replen.free.items.alerts'),
  //   t('plan.replen.free.items.email'),
  //   t('plan.replen.free.items.data_refresh')
  // ]

  const startePlanItems = [
    t('plan.replen.starter.items.variants'),
    t('plan.replen.starter.items.alerts'),
    t('plan.replen.starter.items.email'),
    t('plan.replen.starter.items.export'),
    t('plan.replen.starter.items.data_refresh')
  ]

  // const freePlanButtonLabel = subscriptionPlan === 'free' ? t('button.current_plan') : t('button.downgrade')

  const premiumPlanButtonLabel = subscriptionPlan === 'premium' ? t('button.current_plan') : t('button.upgrade')
  // const isFreePlanDisabled = subscriptionPlan === 'free' || !subscriptionPlan
  const isPremiumPlanDisabled = subscriptionPlan === 'premium'

  return (
    <Page>
      { companyId
        ? (
      <InlineStack gap="400" align="center">
        {/* <PlanCard
          title={t('plan.replen.free.title')}
          price={t('plan.replen.free.price')}
          items={freePlanItems}
          buttonLabel={freePlanButtonLabel}
          handleLoading={loadingPremiumPlan}
          handlePlanSelection={handlePremiumPlanSelection}
          disabled={isFreePlanDisabled}
        /> */}
        <PlanCard
          title={t('plan.replen.starter.title')}
          price={t('plan.replen.starter.price')}
          frequency={t('plan.replen.starter.month')}
          items={startePlanItems}
          trial={t('plan.replen.starter.trial')}
          buttonLabel={premiumPlanButtonLabel}
          handleLoading={loadingPremiumPlan}
          handlePlanSelection={handlePremiumPlanSelection}
          disabled={isPremiumPlanDisabled}
        />
      </InlineStack>)
        : (
        <SkeletonPage>
          <InlineStack gap="400" align="center">
            <Card>
              <Box minHeight='100px' minWidth="350px" padding='300' />
            </Card>
          </InlineStack>
        </SkeletonPage>
          )
      }
    </Page>
  )
}
