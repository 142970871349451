import { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { DataUpdateContext } from '../context/context'

const SubscriptionGuard = () => {
  const { subscriptionPlan, companyId } = useContext(DataUpdateContext)
  if (!companyId) {
    return null
  }
  return subscriptionPlan === 'premium' ? <Outlet /> : <Navigate to='/plan-selection' replace />
}

export default SubscriptionGuard
