import * as Sentry from '@sentry/react'

const environment = process.env.NODE_ENV
console.debug('environment', environment)
const isProductionEnv = ['staging', 'production'].includes(environment)
const sampleRate = isProductionEnv ? 1 : 0
const tracesSampleRate = isProductionEnv ? 0.1 : 0
const replaysSessionSampleRate = isProductionEnv ? 0.1 : 0
const replaysOnErrorSampleRate = isProductionEnv ? 1 : 0

Sentry.init({
  dsn: 'https://4ac8790981080cac34c3c237aaf72d53@o4508142908080128.ingest.us.sentry.io/4508143341142016',
  environment,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  sampleRate,
  // Tracing
  tracesSampleRate, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^\/api\//],
  // Session Replay
  replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
