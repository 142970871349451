// src/store.js
import { configureStore } from '@reduxjs/toolkit'
import authReducer from './apps/stand-alone/reducers/authReducer'

const rootReducer = {
  auth: authReducer
}

const store = configureStore({
  reducer: rootReducer
  // Add other middleware here if needed
})

export default store
