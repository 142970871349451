import React from 'react'
import { Toast } from '@shopify/polaris'

const CustomToast = ({ active, content, error, onDismiss }) => {
  if (!active) return null

  return (
    <Toast
      content={content}
      error={error}
      onDismiss={onDismiss}
    />
  )
}

export default CustomToast
