import React from 'react'
import { Box, BlockStack, Text, Card, InlineStack, List, Divider, Bleed, Button } from '@shopify/polaris'

export const PlanCard = ({ title, price, frequency, items, trial, buttonLabel, handleLoading, handlePlanSelection, disabled }) => (
    <Card>
        <Box padding='300'>
        <BlockStack gap="100" inlineAlign="start">
            <Text as='h2'>
              {title}
            </Text>
            <InlineStack wrap={false} blockAlign='baseline' gap='100'>
                <Text variant='heading2xl'>{price}</Text>
                <Text>/ {frequency}</Text>
            </InlineStack>
        </BlockStack>
        </Box>
        <Divider borderColor="border"/>
        <Box minHeight='100px' width="350px" padding='300'>
            <List type="bullet">
                {(items || []).map(item => (
                    <List.Item key={item}>
                        {item}
                    </List.Item>
                ))}
            </List>
            <Bleed>
            <Box
            background="bg-surface-secondary"
            paddingBlock="300"
            paddingInline="400"
            >
            <Text as="h3" variant="headingSm" fontWeight="medium">
            {trial}
            </Text>
            </Box>
        </Bleed>
        </Box>
        <Box padding='300'>
        <Button
            variant="primary"
            fullWidth
            loading={handleLoading}
            onClick={handlePlanSelection}
            disabled={disabled}
        >
            {buttonLabel}
        </Button>
        </Box>
    </Card>
)
