import React from 'react'
import { Button } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import {
  ExportIcon
} from '@shopify/polaris-icons'

export const ExportButton = ({ gridRef, disabled = false }) => {
  const { t } = useTranslation()

  const exportToExcel = () => {
    if (gridRef.current) {
      gridRef.current.exportDataAsExcel()
    }
  }

  return (
    <Button icon={ExportIcon} disabled={disabled} onClick={exportToExcel}>
      {t('button.export')}
    </Button>
  )
}
