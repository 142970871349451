import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'

export function getCompanyId () {
  // return 4;
  const jwtToken = Cookies.get('authToken')
  if (jwtToken) {
    const decodedToken = jwtDecode(jwtToken)
    if (typeof decodedToken.company_id === 'number') {
      return decodedToken.company_id
    }
  }
  return null
}

export function getUserId () {
  const jwtToken = Cookies.get('authToken')
  if (jwtToken) {
    const decodedToken = jwtDecode(jwtToken)
    if (typeof decodedToken.user_id === 'number') {
      return decodedToken.user_id
    }
  }
  return null
}
