import React from 'react'
import { InlineGrid, Box, BlockStack, Text, Card } from '@shopify/polaris'

const SettingsSection = ({ title, description, children }) => (
  <InlineGrid columns={{ xs: '1fr', md: '2fr 5fr' }} gap='400'>
    <Box
      as='section'
      paddingInlineStart={{ xs: 400, sm: 0 }}
      paddingInlineEnd={{ xs: 400, sm: 0 }}
    >
      <BlockStack gap='400'>
        <Text as='h3' variant='headingMd'>
          {title}
        </Text>
        <Text as='p' variant='bodyMd'>
          {description}
        </Text>
      </BlockStack>
    </Box>
    <Card roundedAbove='sm'>
      <BlockStack gap='400'>
        {children}
      </BlockStack>
    </Card>
  </InlineGrid>
)

export default SettingsSection
