import React, { useEffect, useMemo, useState, useContext, useRef } from 'react'
import '../../../shared/styles/forecast.css'
import { useTranslation } from 'react-i18next'
import { addMonths, addWeeks, format } from 'date-fns'
import { axiosWithCredentials } from '../../../shared/utils/axiosInstance'
import { integerFormatter } from '../../../shared/utils/formatter'
import { getFormattedDates, setDefaultFilterSelectedValues } from '../../../shared/utils'
import { LocationSelector } from '../components/filters/LocationSelector'
import { YearSelector } from '../components/filters/YearSelector'
import { AgGridTable } from '../components/common/AGGridTable'
import { ExportButton } from '../components/agGridParams/buttons/ExportButton'
import { ResetButton } from '../components/agGridParams/buttons/ResetButton'
import { DataUpdateContext } from '../../../shared/context/context'

const InventoryForecastView = () => {
  const { t } = useTranslation()
  const [selectedYear, setSelectedYear] = useState('2024')
  const [locations, setLocations] = useState([])
  const [selectedLocation, setSelectedLocation] = useState('')
  const selectedGrouping = 'weekly'
  const selectedSecondaryMetric = 'forecast'
  const [primeMetrics, setPrimeMetrics] = useState([])
  const [primePivotColumns, setPrimePivotColumns] = useState([])
  const [secondaryMetrics, setSecondaryMetrics] = useState([])
  const [secondaryPivotColumns, setSecondaryPivotColumns] = useState([])
  const { lastUpdate, companyId } = useContext(DataUpdateContext)
  const gridApi = useRef(null)

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel'
        }
      ],
      defaultToolPanel: ''
    }
  }, [])

  useEffect(() => {
    axiosWithCredentials
      .get(`/obius/locations?company_id=${companyId}`)
      .then((response) => {
        if (!response.data) {
          throw new Error('Failed to fetch locations')
        }
        setLocations([{ location_name: t('product.all_locations'), location_id: '' }, ...response.data.locations])
      })
      .catch((error) => {
        console.error('Error fetching locations:', error)
      })
  }, [t, companyId])

  // primary
  useEffect(() => {
    const { startDate, endDate } = getFormattedDates({ selectedYear, selectedGrouping: 'monthly' })
    const params = {
      fields: 'product_name,product_sku,product_variant_name,product_status,is_inventory_tracked',
      populate: 'product',
      pivotColumns: 'period_label',
      pivotValue: 'metric_value',
      metric_name: 'inventory_quantity',
      company_id: companyId,
      'period_start_date>': startDate,
      'period_start_date<': endDate
    }

    let queryParams = new URLSearchParams(params)
    if (!selectedLocation) {
      queryParams += '&!location_id'
    } else {
      queryParams.append('location_id', selectedLocation)
    }
    axiosWithCredentials
      .get(`/obius/metrics?${queryParams.toString()}`)
      .then((response) => {
        const { metrics, pivotColumns } = response.data
        if (!metrics) {
          throw new Error('Failed to fetch data')
        }
        setPrimeMetrics(metrics)
        setPrimePivotColumns(pivotColumns)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [selectedYear, selectedGrouping, selectedLocation, companyId])

  // secondary
  useEffect(() => {
    const { startDate, endDate } = getFormattedDates({ selectedYear, selectedGrouping: 'monthly' })

    const params = {
      fields: 'product_name,product_sku',
      populate: 'product',
      pivotColumns: 'period_label',
      pivotValue: 'metric_value',
      company_id: companyId,
      metric_name: 'weekly_inventory_forecast',
      'period_start_date>': startDate,
      'period_start_date<': endDate
    }
    let queryParams = new URLSearchParams(params)
    if (!selectedLocation) {
      queryParams += '&!location_id'
    } else {
      queryParams.append('location_id', selectedLocation)
    }
    axiosWithCredentials
      .get(`/obius/metrics?${queryParams.toString()}`)
      .then((response) => {
        const { metrics, pivotColumns } = response.data
        if (!metrics) {
          throw new Error('Failed to fetch data')
        }
        setSecondaryMetrics(metrics)
        setSecondaryPivotColumns(pivotColumns)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [selectedYear, selectedGrouping, selectedSecondaryMetric, selectedLocation, companyId])

  /**
   * accepts 2024-03 or 2024-W06
   * @param monthOrWeek
   */
  const isPastDate = (monthOrWeek) => {
    const [year, other] = monthOrWeek.split('-')
    let date = new Date(year + '-01-01')
    if (!other.startsWith('W')) {
      date = addMonths(date, parseInt(other, 10)) // we are interested in start of month after
      return date < new Date()
    }
    const weeks = parseInt(other.substring(1), 10)
    date = addWeeks(date, weeks) // we are interested in start of week after
    return date < new Date()
  }
  const combinedColumns = useMemo(() => {
    if (!primePivotColumns.length) {
      return []
    }
    return [...new Set([...primePivotColumns, ...secondaryPivotColumns])].sort()
  }, [primePivotColumns, secondaryPivotColumns])

  const combinedMetrics = useMemo(() => {
    const products = []
    for (const product of primeMetrics) {
      const data = JSON.parse(JSON.stringify(product))
      const forecastProduct = secondaryMetrics.find(({ product_sku: productSku }) => data.product_sku === productSku) ?? {}
      for (const column of combinedColumns) {
        const forecastColumn = secondaryPivotColumns.find(key => key.endsWith(column.replace(selectedYear, ''))) ?? null
        const prime = parseFloat(product[column] ?? '0')
        const secondary = parseFloat(forecastProduct[forecastColumn] ?? '0')
        data[column] = isPastDate(column) ? prime : secondary
      }

      products.push(data)
    }
    return products
  }, [secondaryMetrics, primeMetrics, secondaryPivotColumns, selectedYear, combinedColumns])

  const onFirstDataRendered = async () => {
    await setDefaultFilterSelectedValues({ filterName: 'is_inventory_tracked', unselectedValues: ['false'], gridApi })
    await setDefaultFilterSelectedValues({ filterName: 'product_status', unselectedValues: ['archived', 'draft'], gridApi })
  }

  const columns = useMemo(() => {
    const formatter = integerFormatter.format

    const weekColumns = combinedColumns.map((key) => ({
      headerName: key,
      field: key,
      filter: 'agNumberColumnFilter',
      width: 120,
      valueGetter: p => p.data && +p.data[key],
      valueFormatter: ({ value }) => formatter(value),
      filterParams: {
        defaultOption: 'greaterThan'
      },
      aggFunc: 'sum'
    }))

    return [
      {
        headerName: t('product.name'),
        field: 'product_name',
        filter: 'agSetColumnFilter',
        cellStyle: { textAlign: 'left' },
        pinned: 'left'

      },
      {
        headerName: t('product.variant'),
        cellStyle: { textAlign: 'left' },
        filter: 'agSetColumnFilter',
        field: 'product_variant_name'
      },
      {
        headerName: t('product.sku'),
        field: 'product_sku',
        filter: 'agSetColumnFilter',
        cellStyle: { textAlign: 'centre' }
      },
      {
        headerName: t('product.is_inventory_tracked'),
        valueGetter: p => p.data && p.data.is_inventory_tracked === 't',
        colId: 'is_inventory_tracked',
        cellDataType: 'boolean',
        filter: 'agSetColumnFilter',
        hide: true
      },
      {
        headerName: t('product.product_status'),
        field: 'product_status',
        filter: 'agSetColumnFilter',
        hide: true
      },
      ...weekColumns
    ]
  }, [t, combinedColumns])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value)
  }
  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value)
  }
  return (
    <div className='dashboard-container'>
      <div className='dashboard-section'>
        <div className='dashboard-title dashboard-dropdown'>
          <div>
            <h1>{t('sidebar.inventory-forecast')}</h1>
            <h2 className='subtitle is-6 mt-1'>{t('common.updated')} {format(new Date(lastUpdate), 'yyyy-MM-dd H:mm')}</h2>
          </div>
          <div className='filters-container'>
            <div className='select-container'>
              <LocationSelector
                handleLocationChange={handleLocationChange}
                selectedLocation={selectedLocation}
                locations={locations}
              />
              <span className='select-divider' />
              <YearSelector handleYearChange={handleYearChange} selectedYear={selectedYear} />
            </div>
          </div>
        </div>
      </div>
      <div className='exports-container'>
        <div className='button-items-section'>
          <div className='button-container'>
            <ExportButton gridRef={gridApi} />
          </div>
          <div>
            <ResetButton gridRef={gridApi} />
          </div>
        </div>
      </div>
      <div className='items-section obius-grid-size-m'>
        <AgGridTable
          colDefs={columns}
          rowData={combinedMetrics}
          grandTotalRow='top'
          onGridReady={onGridReady}
          sideBar={sideBar}
          onFirstDataRendered={onFirstDataRendered}
        />
      </div>
    </div>
  )
}
export default InventoryForecastView
