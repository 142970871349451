import { format, startOfISOWeekYear } from 'date-fns'
import { MD5 } from 'crypto-js'
export function getCookieDomain () {
  let domainParts
  const currentDomain = window.location.hostname
  domainParts = currentDomain.split('.')
  if (domainParts.length > 2) domainParts = domainParts.slice(-2)
  return `.${domainParts.join('.')}`
}

export function objectToId (object) {
  const string = JSON.stringify(object)
  const hash = MD5(string).toString()
  return hash.match(/(.{8})(.{4})(.{4})(.{4})(.{12})/).slice(1).join('-')
}

export function getFormattedDates ({ selectedYear, selectedGrouping = 'weekly' }) {
  let startDate, endDate
  if (selectedGrouping === 'monthly') {
    startDate = format(new Date(+selectedYear, 0, 1), 'yyyy-MM-dd')
    endDate = format(new Date(+selectedYear + 1, 0, 1), 'yyyy-MM-dd')
  } else {
    startDate = format(startOfISOWeekYear(new Date(selectedYear, 0, 31)), 'yyyy-MM-dd')
    endDate = format(startOfISOWeekYear(new Date(+selectedYear + 1, 0, 31)), 'yyyy-MM-dd')
  }
  return { startDate, endDate }
}

export async function setDefaultFilterSelectedValues ({ filterName, unselectedValues, selectedValues, gridApi }) {
  let filteredValues
  const filterInstance = await gridApi.current.getColumnFilterInstance(filterName)
  if (unselectedValues) filteredValues = filterInstance.getValues().filter(value => !unselectedValues.includes(value))
  if (selectedValues) filteredValues = filterInstance.getValues().filter(value => selectedValues.includes(value))
  filterInstance.setModel({ values: filteredValues })
  gridApi.current.onFilterChanged()
}

export const hoursOptions = []
for (let i = 0; i < 24; i++) {
  hoursOptions.push({ label: convertToAmPmFormat(String(i)), value: String(i) })
}

export function translateCronJobFormat (selectedDayOptions, selectedHourOptions) {
  let cronSchedule = '0 '
  const localHour = +selectedHourOptions[0]
  // Convert selected hours to cron format
  if (localHour) {
    const date = new Date()
    const timezoneOffsetHours = date.getTimezoneOffset() / 60
    date.setHours(localHour)
    const utcHour = date.getUTCHours()
    if (localHour + timezoneOffsetHours < 0) {
    // If it is, decrement the day by 1, and handle the case when the day is Sunday
      selectedDayOptions = selectedDayOptions.map(day => +day === 0 ? 6 : +day - 1)
    } else if (localHour + timezoneOffsetHours >= 24) {
    // If the local hour is greater than the UTC hour, increment the day by 1, and handle the case when the day is Saturday
      selectedDayOptions = selectedDayOptions.map(day => +day === 6 ? 0 : +day + 1)
    }
    cronSchedule += utcHour
  } else {
    //  defaulted to 8am UTC
    cronSchedule += '8'
  }
  cronSchedule += ' * * '
  // Convert selected days to cron format
  if (selectedDayOptions.length > 0) {
    cronSchedule += selectedDayOptions.sort((a, b) => a - b).join(',')
  } else {
    cronSchedule += '*'
  }
  return cronSchedule
}

export function getCronDays (cronFormat) {
  const parts = cronFormat.split(' ')
  const utcHour = parts[1]
  const days = parts[4]
  if (days === '*') return [0, 1, 2, 3, 4, 5, 6]
  const date = new Date()
  const timezoneOffsetHours = date.getTimezoneOffset() / 60
  const daysArray = days.split(',').map(day => {
    if (timezoneOffsetHours > utcHour) {
      day = +day === 0 ? 6 : +day - 1
    } else if (utcHour - timezoneOffsetHours >= 24) {
      day = +day === 6 ? 0 : +day + 1
    }
    return +day
  }).sort((a, b) => a - b)
  return daysArray
}

export function getCronHour (cronFormat) {
  const hour = cronFormat.split(' ')[1]
  const date = new Date()
  date.setUTCHours(hour)
  const localHour = date.getHours()
  return localHour
}

export function convertToAmPmFormat (time) {
  const hour = parseInt(time, 10)
  let amPmTime

  if (hour === 0) {
    amPmTime = '12:00 AM'
  } else if (hour < 12) {
    amPmTime = `${hour}:00 AM`
  } else if (hour === 12) {
    amPmTime = '12:00 PM'
  } else {
    amPmTime = `${hour - 12}:00 PM`
  }

  return amPmTime
}
