import { useEffect, useMemo, useState, useRef, useCallback, useContext } from 'react'
import { axiosWithCredentials } from '../../../shared/utils/axiosInstance'
import { useTranslation } from 'react-i18next'
import { AgGridTable } from '../components/common/AGGridTable'
import { currencyFormatter } from '../../../shared/utils/formatter'
import { setDefaultFilterSelectedValues } from '../../../shared/utils'
import { GroupingSelector } from '../components/filters/GroupingSelector'
import { format } from 'date-fns'
import { DataUpdateContext } from '../../../shared/context/context'
import { ExportButton } from '../components/agGridParams/buttons/ExportButton'
import { ResetButton } from '../components/agGridParams/buttons/ResetButton'
const PivotView = () => {
  const { t } = useTranslation()
  const [products, setProducts] = useState([])
  const { lastUpdate, companyId } = useContext(DataUpdateContext)
  const [error, setError] = useState(null)
  const [selectedGrouping, setSelectedGrouping] = useState('monthly')
  const gridApi = useRef(null)

  const gridOptions = {
    enableCharts: true,
    enableRangeSelection: true
  }

  const onGridReady = useCallback(async (params) => {
    gridApi.current = params.api
    gridApi.current.onFilterChanged()
  }, [gridApi])

  const handleFilterChange = (event) => {
    setSelectedGrouping(event.target.value)
  }

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
          }
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel'
        }
      ],
      defaultToolPanel: 'columns'
    }
  }, [])

  useEffect(() => {
    const params = new URLSearchParams({
      fields: 'collection_name,location_name,product_name,product_variant_name,product_sku,product_supplier,period_label,product_status,product_barcode,product_seasonal,type,moq,target_days_inventory,min_days_inventory,lead_time,sell_price,cost,is_material,is_inventory_tracked,metric_value',
      populate: 'product,collection,location',
      metric_name: `${selectedGrouping}_sales`,
      company_id: companyId,
      location_id: '',
      product_id: '',
      limit: 100000
    })

    axiosWithCredentials
      .get(`/obius/metrics?${params.toString()}`)
      .then((response) => {
        if (!response.data) {
          throw new Error('Failed to fetch data')
        }
        const { metrics } = response.data
        if (!metrics) {
          setProducts([])
          return
        }
        setProducts(metrics)
        setError(null)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        setError(error)
      })
  }, [selectedGrouping, companyId])

  const onFirstDataRendered = async () => {
    await setDefaultFilterSelectedValues({ filterName: 'location_name', unselectedValues: ['Deleted Location'], gridApi })
    await setDefaultFilterSelectedValues({ filterName: 'year', selectedValues: ['2024'], gridApi })
    await setDefaultFilterSelectedValues({ filterName: 'is_inventory_tracked', unselectedValues: ['false'], gridApi })
    await setDefaultFilterSelectedValues({ filterName: 'product_status', unselectedValues: ['archived', 'draft'], gridApi })
  }

  const columns = useMemo(() => {
    const baseColumns = [
      {
        headerName: t('metric.year'),
        colId: 'year',
        filter: 'agSetColumnFilter',
        valueGetter: ({ data }) => data && data.period_label && data.period_label.substring(0, 4),
        pivot: true,
        enablePivot: true
      },
      {
        field: 'collection_name',
        filter: 'agSetColumnFilter',
        pinned: 'left',
        rowGroup: true,
        hide: true,
        enableRowGroup: true,
        enablePivot: true,
        valueFormatter: ({ value }) => t(`${value}`)
      },
      {
        headerName: t('product.name'),
        cellStyle: { textAlign: 'left' },
        field: 'product_name',
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: t('product.variant'),
        field: 'product_variant_name',
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: t('product.sku'),
        field: 'product_sku',
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: t('product.location'),
        field: 'location_name',
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: t('product.supplier'),
        field: 'product_supplier',
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: t('product.product_status'),
        field: 'product_status',
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: t('product.barcode'),
        field: 'product_barcode',
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: t('product.seasonal'),
        field: 'product_seasonal',
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: t('product.period_label'),
        field: 'period_label',
        filter: 'agSetColumnFilter',
        pivot: true,
        enablePivot: true
      },
      {
        headerName: t('product.type'),
        field: 'type',
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: t('product.moq'),
        field: 'moq',
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: t('product.target_days_inventory'),
        field: 'target_days_inventory',
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: t('product.min_days_inventory'),
        field: 'min_days_inventory',
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: t('product.lead_time'),
        field: 'lead_time',
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: t('product.sell_price'),
        field: 'sell_price',
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: t('product.cost'),
        field: 'cost',
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: t('product.is_material'),
        field: 'is_material',
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: t('product.is_inventory_tracked'),
        field: 'is_inventory_tracked',
        cellDataType: 'boolean',
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true
      },
      {
        headerName: t('product.metric_value'),
        field: 'metric_value',
        aggFunc: 'sum',
        enablePivot: true,
        valueGetter: ({ data }) => data && data.metric_value !== undefined ? +data.metric_value : null,
        valueFormatter: ({ value }) => currencyFormatter.format(value)
      }
    ]
    return [...baseColumns]
  }, [t])

  return (
    <div className='dashboard-container'>
      <div className='dashboard-section'>
        <div className='dashboard-title dashboard-dropdown'>
          <div>
            <h1>{t('sidebar.reporting')}</h1>
            <h2 class='subtitle is-6 mt-1'>{t('common.updated')} {format(new Date(lastUpdate), 'yyyy-MM-dd H:mm')}</h2>
          </div>
          <GroupingSelector
            handleGroupingChange={handleFilterChange}
            selectedGrouping={selectedGrouping} t={t}
          />
        </div>
      </div>
      <div className='exports-container'>
        <div className='button-items-section'>
          <div className='button-container'>
            <ExportButton gridRef={gridApi} />
          </div>
          <div>
            <ResetButton gridRef={gridApi} />
          </div>
        </div>
      </div>
      <div className='items-section obius-grid-size-l'>
        {error && error.response && error.response.status === 404
          ? (<p>{t('error.no_data')}</p>)
          : (
              products.length === 0
                ? (<p>{t('error.no_data')}</p>)
                : (<AgGridTable
                    rowData={products}
                    colDefs={columns}
                    grandTotalRow='top'
                    pivotMode
                    sideBar={sideBar}
                    onFirstDataRendered={onFirstDataRendered}
                    onGridReady={onGridReady}
                    gridOptions={gridOptions}
                   />)
            )}
      </div>
    </div>
  )
}

export default PivotView
