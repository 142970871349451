import React from 'react'

import { BellIcon, GearIcon } from '../img/icons'
import '../../../shared/styles/top-navigation.css'

const TopNavigation = () => {
  return (
    <div className='top-navigation'>
      <div className='search-bar'>
        {/* <i className="material-icons">search</i>
        <span>Search an item</span> */}
      </div>
      <div className='action-buttons'>
        <button className='icon-button'>
          <span className='icon'><BellIcon /></span>
        </button>
        <button className='icon-button'>
          <span className='icon'><GearIcon /></span>
        </button>
      </div>
    </div>
  )
}

export default TopNavigation
