import { Button, Card, Text, Box, InlineGrid, Tooltip } from '@shopify/polaris'
import { currencyFormatter } from '../../../shared/utils/formatter'
import { ArrowRightIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'

export const InventoryCard = ({ title, totalSellPrice, description, tone, totalProducts, onCardClick, statusTooltip }) => {
  const { t } = useTranslation()
  return (
    <Card title={title} sectioned padding='500'>
        <Box padding='200' minHeight="40px">
          <Tooltip content={t('inventory_card.loss_sales_tooltip')} hasUnderline>
            <Text variant='headingLg' fontWeight='bold' as="span">
              {(totalSellPrice && currencyFormatter.format(totalSellPrice))}
            </Text>
          </Tooltip>
      </Box>
      <InlineGrid columns={['twoThirds', 'oneThird']}>
        <Box padding='200'>
          {
            statusTooltip
              ? (
                  <Tooltip content={statusTooltip} hasUnderline>
                    <Text as='span' variation='subdued'>{description}</Text>
                  </Tooltip>
                )
              : (
                  <Text as='h4' variation='subdued'>{description}</Text>
                )
          }
          <Text variant='headingXs' fontWeight='normal' tone={tone}>
            &#9632; {totalProducts} products
          </Text>
        </Box>
        <Button
          variant='primary'
          icon={ArrowRightIcon}
          onClick={onCardClick}
          disabled={totalProducts === 0}
        />
      </InlineGrid>
    </Card>
  )
}
