import { useCallback, useState, useEffect } from 'react'
import {
  LegacyCard,
  ResourceList,
  ResourceItem,
  Text,
  Layout,
  Page,
  Card,
  EmptyState,
  SkeletonPage,
  SkeletonBodyText,
  SkeletonDisplayText,
  BlockStack,
  Box,
  Banner,
  Filters,
  InlineStack,
  Badge
} from '@shopify/polaris'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useBomsList } from '../hooks/useBomsList'

export const BundleListView = () => {
  const { t } = useTranslation()
  const [showBanner, setShowBanner] = useState(true)
  const {
    boms,
    currentPageBoms,
    isLoading,
    error,
    handleNextPage,
    handlePreviousPage,
    hasNextPage,
    hasPreviousPage,
    handleFiltersQueryChange,
    queryValue,
    isFreePlan
  } = useBomsList()
  const navigate = useNavigate()

  useEffect(() => {
    if (isFreePlan) navigate('/plan-selection')
  }, [isFreePlan, navigate])

  const handleCreate = useCallback(() => {
    navigate('/bundles/new')
  }, [navigate])

  const emptyStateMarkup = (
    <EmptyState
      heading={t('bundles.createBundlesToGetStarted')}
      action={{ content: t('bundles.createBundles'), onAction: handleCreate }}
      image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
    >
      <p>
        {t('bundles.contactSupportToUploadBundles')}
      </p>
    </EmptyState>
  )

  if (isLoading) {
    return (
      <SkeletonPage primaryAction>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <SkeletonDisplayText size="large" />
              <BlockStack gap='200'>
                <SkeletonBodyText />
                <SkeletonBodyText />
                <SkeletonBodyText />
                <SkeletonBodyText />
                <SkeletonBodyText />
              </BlockStack>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    )
  }

  if (error) {
    return (
      <Page title={t('bundles.bundles')}>
        <Card>
          <Text variant="bodyMd" color="critical">{t('bundles.error')}: {error}</Text>
        </Card>
      </Page>
    )
  }

  return (
    <Page
      title={t('bundles.bundles')}
      primaryAction={{
        content: t('bundles.createBundle'),
        onAction: handleCreate
      }}
      divider
    >
      {showBanner && (
        <div>
          <Box paddingBlock='400'>
            <Banner
              title={t('banner.beta')}
              onDismiss={() => setShowBanner(false)}
              tone='info'
            >
            </Banner>
          </Box>
        </div>
      )}
      <Card>
        <Layout>
          <Layout.Section>
            <LegacyCard>
              <ResourceList
                filterControl={
                  <Filters
                    filters={[]}
                    queryValue={queryValue}
                    onQueryChange={handleFiltersQueryChange}
                  />
                }
                emptyState={boms.length === 0 ? emptyStateMarkup : undefined}
                resourceName={{ singular: t('bundles.bundle'), plural: t('bundles.bundles') }}
                items={currentPageBoms}
                renderItem={(item) => {
                  const bomId = item.bom_id
                  const outputProductName = item.output_product.product_name
                  const productVariant = item.output_product.product_variant_name
                  const bomStatus = item.status

                  return (
                    <ResourceItem
                      id={bomId}
                      onClick={() => {
                        navigate(`/bundles/${bomId}`)
                      }}
                      accessibilityLabel={`${t('bundles.viewDetailsFor')} ${outputProductName}`}
                    >
                      <InlineStack gap='200' blockAlign="center" align="space-between">
                        <Box >
                          <Text variant="bodyMd" fontWeight="bold" as="h3">
                            {outputProductName}
                          </Text>
                          <div>{productVariant}</div>
                        </Box>
                        <Badge tone={bomStatus === 'active' ? 'success' : 'info'}>
                          {t(`status_card.statuses.${bomStatus}`)}
                        </Badge>
                      </InlineStack>
                    </ResourceItem>
                  )
                }}
                pagination={{
                  hasNext: hasNextPage,
                  onNext: handleNextPage,
                  hasPrevious: hasPreviousPage,
                  onPrevious: handlePreviousPage
                }}
              />
            </LegacyCard>
          </Layout.Section>
        </Layout>
      </Card>
    </Page>
  )
}
