import React from 'react'
import Sidebar from '../components/Sidebar'
import TopNavigation from '../components/TopNavigation'
import { Footer } from '../../../shared/components/Footer'
import '../../../shared/styles/default-layout.css'
import { NewReleaseBanner } from '../../../shared/components/NewReleaseBanner'

function DefaultLayout ({ children }) {
  return (
    <div className='main-container'>
      <Sidebar />
      <div className='main-content'>
        <TopNavigation />
        <NewReleaseBanner />
        {children}
        <Footer />
      </div>
    </div>
  )
}

export default DefaultLayout
