export const YearSelector = ({ handleYearChange, selectedYear }) => (
  <select className='select-style' value={selectedYear} onChange={handleYearChange}>
    <option value='2024'>2024</option>
    <option value='2023'>2023</option>
    <option value='2022'>2022</option>
    <option value='2021'>2021</option>
    <option value='2020'>2020</option>
    <option value='2019'>2019</option>
    <option value='2018'>2018</option>
  </select>
)
