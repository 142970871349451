export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user
})

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error
})

export const login = (username, password) => {
  // Perform the login API call here
  return (dispatch) => {
    // Simulate an asynchronous API call
    setTimeout(() => {
      // Replace this with your actual API call logic
      if (username === 'validUser' && password === 'password') {
        dispatch(loginSuccess({ username: 'validUser' }))
      } else {
        dispatch(loginFailure('Invalid credentials'))
      }
    }, 1000) // Simulating a 1-second delay
  }
}
