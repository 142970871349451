import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { axiosWithCredentials } from '../../../shared/utils/axiosInstance'
import { currencyFormatter } from '../../../shared/utils/formatter'
import '../../../shared/styles/ag-grid.css'
import { AgGridTable } from '../components/common/AGGridTable'
import { getFormattedDates, setDefaultFilterSelectedValues } from '../../../shared/utils'
import { DataUpdateContext } from '../../../shared/context/context'
import { ExportButton } from '../components/agGridParams/buttons/ExportButton'
import { ResetButton } from '../components/agGridParams/buttons/ResetButton'
import { format } from 'date-fns'

const InventoryView = () => {
  const { t } = useTranslation()
  const [products, setProducts] = useState([])
  const [locations, setLocations] = useState([])
  const [selectedLocation, setSelectedLocation] = useState('')
  const [selectedFilter, setSelectedFilter] = useState('inventoryHistoryStockData')
  const [selectedYear, setSelectedYear] = useState('2024')
  const [weekColumns, setWeekColumns] = useState([])
  const { lastUpdate, companyId } = useContext(DataUpdateContext)
  const gridApi = useRef(null)

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel'
        }
      ],
      defaultToolPanel: ''
    }
  }, [])

  useEffect(() => {
    axiosWithCredentials
      .get(`/obius/locations?company_id=${companyId}`)
      .then((response) => {
        if (!response.data) {
          throw new Error('Failed to fetch locations')
        }
        setLocations([{ location_name: t('product.all_locations'), location_id: '' }, ...response.data.locations])
      })
      .catch((error) => {
        console.error('Error fetching locations:', error)
      })
  }, [t, companyId])

  useEffect(() => {
    const { startDate, endDate } = getFormattedDates({ selectedYear })

    const params = new URLSearchParams({
      fields: 'product_name,product_variant_name,product_sku,product_supplier,product_status,is_inventory_tracked',
      populate: 'product',
      pivotColumns: 'period_label',
      pivotValue: 'metric_value',
      metric_name: selectedFilter === 'inventoryHistoryStockData' ? 'inventory_quantity' : 'inventory_value',
      company_id: companyId
    })

    if (selectedLocation) {
      params.append('location_id', selectedLocation)
    } else {
      params.append('!location_id', '')
    }

    params.append('period_start_date>', startDate)
    const paramString = `${params.toString()}&period_start_date<${endDate}&period_label<>LAST_30_DAYS`

    axiosWithCredentials
      .get(`/obius/metrics?${paramString}`)
      .then((response) => {
        const { metrics, pivotColumns } = response.data
        setWeekColumns(pivotColumns)
        setProducts(metrics)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [selectedLocation, selectedFilter, selectedYear, companyId])

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value)
  }

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value)
  }

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value)
  }
  const onGridReady = (params) => {
    gridApi.current = params.api
  }
  const generateWeekColumns = useCallback(() => {
    return weekColumns.map(week => ({
      headerName: week,
      filter: 'agNumberColumnFilter',
      valueGetter: p => p.data && +p.data[week],
      filterParams: {
        defaultOption: 'greaterThan'
      },
      aggFunc: 'sum',
      width: 133,
      valueFormatter: ({ value }) => {
        const nValue = parseFloat(value)
        if (selectedFilter === 'inventoryHistoryValueData') {
          return isNaN(nValue) ? 0 : currencyFormatter.format(nValue)
        } else {
          return isNaN(nValue) ? 0 : Math.trunc(nValue)
        }
      }
    }))
  }, [weekColumns, selectedFilter])

  const onFirstDataRendered = async () => {
    await setDefaultFilterSelectedValues({ filterName: 'is_inventory_tracked', unselectedValues: ['false'], gridApi })
    await setDefaultFilterSelectedValues({ filterName: 'product_status', unselectedValues: ['archived', 'draft'], gridApi })
  }

  const columns = useMemo(() => {
    const baseColumns = [
      {
        headerName: t('product.name'),
        field: 'product_name',
        filter: 'agSetColumnFilter',
        cellStyle: { textAlign: 'left' },
        pinned: 'left'
      },
      {
        headerName: t('product.variant'),
        cellStyle: { textAlign: 'left' },
        filter: 'agSetColumnFilter',
        field: 'product_variant_name'
      },
      {
        headerName: t('product.sku'),
        field: 'product_sku',
        filter: 'agSetColumnFilter'
      },
      {
        headerName: t('product.supplier'),
        field: 'product_supplier',
        filter: 'agSetColumnFilter'
      },
      {
        headerName: t('product.is_inventory_tracked'),
        valueGetter: p => p.data && p.data.is_inventory_tracked === 't',
        colId: 'is_inventory_tracked',
        cellDataType: 'boolean',
        filter: 'agSetColumnFilter',
        hide: true
      },
      {
        headerName: t('product.product_status'),
        field: 'product_status',
        filter: 'agSetColumnFilter',
        hide: true
      }
    ]

    const dynamicWeekColumns = generateWeekColumns(products)
    return [...baseColumns, ...dynamicWeekColumns]
  }, [products, generateWeekColumns, t])

  return (
    <div className='dashboard-container'>
      <div className='dashboard-section'>
        <div className='dashboard-title dashboard-dropdown'>
          <div>
            <h1>{t('sidebar.inventory')}</h1>
            <h2 className='subtitle is-6 mt-1'>{t('common.updated')} {format(new Date(lastUpdate), 'yyyy-MM-dd H:mm')}</h2>
          </div>
          {/* <span>Updated: { updated }</span> */}
          <div className='filters-container'>
            <div className='select-container'>
              <select className='select-style' value={selectedFilter} onChange={handleFilterChange}>
                <option value='inventoryHistoryStockData'>{t('product.qty_stock')}</option>
                <option value='inventoryHistoryValueData'>{t('product.inventory_value')}</option>
              </select>
            </div>
            <div className='select-container'>
              <select
                className='select-style' value={selectedLocation === null ? '' : selectedLocation}
                onChange={handleLocationChange}
              >
                {locations.map((location, index) => (
                  <option key={index} value={location.location_id}>
                    {location.location_name}
                  </option>
                ))}
              </select>
            </div>
            <div className='select-container'>
              <select className='select-style' value={selectedYear} onChange={handleYearChange}>
                <option value='2024'>2024</option>
                <option value='2023'>2023</option>
                <option value='2022'>2022</option>
                <option value='2021'>2021</option>
                <option value='2020'>2020</option>
                <option value='2019'>2019</option>
                <option value='2018'>2018</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className='exports-container'>
        <div className='button-items-section'>
          <div className='button-container'>
            <ExportButton gridRef={gridApi} />
          </div>
          <div>
            <ResetButton gridRef={gridApi} />
          </div>
        </div>
      </div>
      <div className='items-section obius-grid-size-m'>
        {products && (
          <AgGridTable
            colDefs={columns}
            rowData={products}
            grandTotalRow='top'
            onGridReady={onGridReady}
            sideBar={sideBar}
            onFirstDataRendered={onFirstDataRendered}
          />
        )}
      </div>
    </div>
  )
}

export default InventoryView
