import { FooterHelp, Link } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

export const Footer = () => {
  const { t } = useTranslation()
  return (
    <FooterHelp>
        {t('footer.help')}
        <Link url="https://obius.atlassian.net/servicedesk/customer/portal/1" external>
            {t('footer.link')}
        </Link>
    </FooterHelp>
  )
}
