import { backendRequest } from '../utils/axiosInstance'

export const companyService = {
  saveCompany: async ({ companyId, appBridge, update }) => {
    try {
      const response = await backendRequest({
        method: 'PATCH',
        url: `/obius/companies/${companyId}`,
        data: update,
        appBridge
      })
      return response.data
    } catch (error) {
      console.error('Error saving company:', error)
      throw error
    }
  }
}
